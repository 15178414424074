
@each $colorName, $matColor in $material-colors {
  $color500: map-get($matColor, 500);
  $foreground500: map-get(map-get($matColor, contrast), 500);
  .#{''+$colorName+''} {
    background-color: $color500 !important;

    // Material CDK Table
    .mat-table {
      background: transparent !important;
    }
    .ngx-datatable.material *,
    .mat-table,
    .mat-cell,
    .mat-header-cell {
      color: $foreground500 !important;
    }
    .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover,
    .ngx-datatable.material:not(.cell-selection)
      .datatable-body-row:hover
      .datatable-row-group {
      background: $color500, 5;
    }
  }
  // Text color
  .text-#{''+$colorName+''} {
    color: $color500;
  }
  // Sidebar backgrounds
  .sidebar-#{''+$colorName+''} {
    .branding {
        background: $color500 !important;
        color: $foreground500 !important;
    }
    .navigation-hold {
      background: $color500 !important;

      a,
      .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret),
      .sidenav li.open > div > a > span:not(.menuitem-badge),
      .sidenav li.open > a > span,
      .icon-menu .mat-raised-button,
      .app-user .app-user-name,
      .app-logo-text {
        
        color: $foreground500 !important;
       

      } a{

        &::after{
          transition: all ease-in-out .5s !important;
          background-color: #4997f7  !important;
          content: "" !important;
          height: 70% !important;
          width: 90% !important;
          display: block !important;
          opacity: 0;
          position: absolute !important;
          border-radius: 0.5rem;
          padding-top: 0px !important;
          padding-bottom: 0px !important;
          color: #fff !important;


          left:5%;
          z-index: -1;
        }
        &:hover{
          transition: all ease-in-out .5s !important;
          

          &::after {
            transition: all ease-in-out .5s !important;
            position: absolute !important;
            content: "" !important;
            background-color: #4997f7 !important;
            opacity: 1;
            color: #fff !important;
            padding-top: 0px !important;
            padding-bottom: 0px !important;
            border-radius: 0.5rem;
            height: 90% !important;
            width: 90% !important;
            z-index: -1;
            left: 5%;
           
          }
        }
        // font-weight: 500 !important;
        .item-name .lvl1{
        }
        .item-name .lvl2{}
        .lvl3{
          // background-color: red !important;
        }
      }
      .app-user .app-user-photo,
      .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret) {
        border-color: #fff !important;
      }
      .icon-menu .mat-raised-button {
        // background: transparent !important;
      }
      .app-user {
        // background: darken($color500, 5) !important;
      }
      li.open {
        // background: #4997f7 !important;
        > a {
          .onli::before{
            // display: flex;
          // justify-content: space-around;
          // align-items: center;
          width: 1% !important;
          transition: 1s ease-in-out !important;
          font-family: 'Material Icons';
          content: 'keyboard_arrow_right';
          font-size: 14px;
          margin-left: -30px !important;
          // padding-right: 10px !important;
          padding-right: 20px !important;
          }

        }
      }
      .text-muted {
      }
    }
  }
  
  // Footer background
  .footer-#{''+$colorName+''} {
    .main-footer {
      background: $color500 !important;
      color: $foreground500 !important;
    }
  }


  // Generate classes for each color shades
  $contrastMap: map-get($matColor, contrast);
  @each $shade, $value in $matColor {
    @if $shade == contrast {


    } @else {
      .#{''+$colorName+''}-#{$shade} {
        background-color: $value !important;
      }
      // Foreground text color
      .#{''+$colorName+''}-#{$shade}-fg {
        color: map-get($contrastMap, $shade) !important;
      }

      // Text color
      .text-#{''+$colorName+''}-#{$shade} {
        color: $value !important;
      }
    }

  }

}


