.btn {
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    color: rgb(33 37 41);
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    background-color: rgb(0 0 0 / 0%);
    border: 1px solid rgb(0 0 0 / 0%);
    padding: 0.375rem 0.75rem;
    font-size: 12px !important;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}


.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1rem !important;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}
.custom-btn-drop{
}
.dropdown-item {
  display: block;
  width: 50% !important;
  padding:12px !important;
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: left;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  border-radius: 0px !important;
  &:hover{
    background-color: #0675e4 !important;
  }

}
.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 5rem !important; 
  padding: 0.5rem 0;
  margin: 0;
  font-size: 12px !important;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
}
.dropdown-menu {
  border: none !important;
  border-radius: 0% !important;
  position: absolute;
  left: -60px !important;
  top: 15px !important;
  z-index: 1;
  padding: 0 !important;
  // box-shadow: 1px 7px 3px rgba(0, 0, 0, 0.31) !important;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.31) !important;
}
.eusssss{
  background-color: #fff !important;
  cursor: pointer;
  padding: 2px !important;
  &:hover{
    background-color: #b9bbbe !important;
  cursor: pointer;

  }
}
// .messi {
//     border: .5px solid rgb(239, 241, 253) !important ;
//     border-radius:2px !important ;


// }