

@font-face {
    font-family: 'MyFont';
    src: url('../../font/RobotoCondensed-Regular.ttf');
    font-size: 14px !important;

  }
  *{
    font-family: "MyFont";
  }
.required_champs {
    color: red;
}

.custom-input { 
    // border-radius: 30px;

    &:focus {
        border: 1px solid #eff1fd !important;
        box-shadow: none !important;
    }
}

input {
    writing-mode: horizontal-tb !important;
    // font-style: ;
    // font-variant-ligatures: ;
    // font-variant-caps: ;
    // font-variant-numeric: ;
    // font-variant-east-asian: ;
    // font-weight: ;
    // font-stretch: ;
    // font-size: ;
    // font-family: ;
    text-rendering: auto;
    color: fieldtext;
    letter-spacing: normal;
    word-spacing: normal;
    line-height: normal;
    text-transform: none;
    text-indent: 0px;
    text-shadow: none;
    text-align: start;
    -webkit-rtl-ordering: logical;
    cursor: text;
}

button,
input,
select,
a {
    outline: none !important;
    font-size: 14px !important;
    text-decoration: none;
}

.form-control, input[type=search] {
    display: block;
    width: 100%;
    padding: .5rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1rem;
    color: rgb(33 37 41);
    background-color: rgb(255 255 255);
    background-clip: padding-box;
    border: 1px solid rgb(206 212 218);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

label,
.form-check {
    font-size: 14px !important;
    line-height: 1.42857 !important;
    color: #414244 !important;
    font-weight: 400 !important;
    text-shadow: rgb(164, 164, 167) .5px .5px .5px !important;
    font-weight: 700 !important;
}

.required_champs , .text-danger {
    color: red !important;
    font-weight: 700 !important;
    text-shadow: red 1px 1px 1px!important;

}

label {
    font-weight: bold !important;
    // text-shadow: #000 .1px 0px .1px !important;
    font-size: 14px;
    // letter-spacing: 1px;
}

label {
    cursor: default;
}

.custom-photo {
    margin-bottom: 10px !important;
    height: 100px;
    border: 1px solid #b7b7b4;
}

.form-check-input {
    width: 1.10em !important;
    height: 1.10em !important;
    margin-top: 0.125em;
    vertical-align: top;
    background-color: rgba(255, 255, 255, 0.87);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
     border: 1px solid rgba(0, 0, 0, 0.25);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
    print-color-adjust: exact;
    transition: background-color 0.15s ease-in-out, background-position 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.chexkBox_Custom {
    // display: flex;
    // align-items: center;
    // justify-content: space-around;

    margin-top: 27px !important;
}

.egret-navy .mat-form-field.mat-warn .mat-input-element,
.egret-navy .mat-form-field-invalid .mat-input-element {
    caret-color: rgb(255 61 87);
}

.mat-input-element mat-form-field-autofill-control ng-tns-c65-0 ng-pristine ng-invalid cdk-text-field-autofill-monitored ng-touched {}

.mat-form-field-flex>.mat-form-field-infix {
    padding: 0.8em 0px !important;

}


.mat-form-field {
    /* font-size: inherit; */
    /* font-weight: 400; */
    /* line-height: 1.125; */
    letter-spacing: 0px !important;
}

.signup-form .ng-pristine .ng-invalid .ng-touched {
    text-shadow: rgb(255 61 87) 1px 1px 1px !important;
}

.custom-champ-recherche {
    padding: 20px;
    background-color: rgb(243, 206, 192);
    border-radius: 10px;
    margin-bottom: 50px;
    vertical-align: middle !important;

    &:hover {
        background-color: rgb(147, 189, 137);
    }
}
// .croix{
//     position: absolute;
//     left: 100px;
//     font-size: 10px;
// }
// input[type="search"] {
//     border: none;
//     background: transparent;
//     margin: 0;
//     padding: 7px 8px;
//     font-size: 14px;
//     color: inherit;
//     border: 1px solid transparent;
//     border-radius: inherit;
//   }
  
//   input[type="search"]::placeholder {
//     color: #dfd8d8;
//   }
//   input[type=search] {
//     border: none;
//     background: transparent;
//     margin: 0;
//     padding: 7px 8px;
//     font-size: 14px;
//     color: inherit;
//     border: 1px solid transparent;
//     border-radius: inherit;
// }
input[type="search" i]:enabled:read-write:-webkit-any(:focus, :hover)::-webkit-search-cancel-button {
    opacity: 1 !important;
    // border: .1px solid red !important;
    border-radius: 50px !important;
    font-size: 10px !important;
    pointer-events: auto;
    color : red !important;
}

.mat-tab-label {
    height: 48px;
    padding: 0 24px;
    cursor: pointer;
    box-sizing: border-box;
    opacity: .6;
    min-width: 160px;
    text-align: center;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    position: relative;
}
.form-check-input{
    cursor: pointer !important;
}
input[type="search" i]::-webkit-search-cancel-button {
    appearance: auto;
    display: block;
    margin-inline-start: 1px;
    cursor: pointer !important;
    margin-right: 3px;
    opacity: 0;
    pointer-events: none;
    -webkit-user-modify: read-only !important;
    user-select: none !important;
    flex: 0 0 auto;
}