
@font-face {
    font-family: 'MyFont';
    src: url('../../font/RobotoCondensed-Regular.ttf');
    font-size: 14px !important;

  }
.swal2-icon.swal2-warning.swal2-icon-show {
    display: none !important;

}

.swal2-container.swal2-center>.swal2-popup {
    width: 400px;
    font-size: 12px;
}

.swal2-title {
    position: relative;
    max-width: 100%;
    margin: 0;
    padding: 0.8em 1em 0;
    color: inherit;
    font-size: 1.875em;
    font-weight: 600;
    text-align: center;
    text-transform: none;
    word-wrap: break-word;
}

.swal2-popup.swal2-modal.swal2-show {
    font-size: 14px;
    width: 30rem;
    padding-bottom: 2.2rem;
}

.swal2-popup {
    display: none;
    position: relative;
    box-sizing: border-box;
    grid-template-columns: minmax(0, 100%);
    width: 32em;
    max-width: 100%;
    padding: 0 0 1.25em;
    border: none;
    border-radius: 5px;
    background: rgb(255 255 255);
    color: rgb(84 84 84);
    font-size: 1rem;
}

.swal2-title {
    position: relative;
    max-width: 100%;
    text-shadow: rgb(129, 125, 125) .1px .5px .2px !important;
    margin: 0;
    padding: 0.8em 1em 0;
    color: inherit;
    font-size: 1.5em;
    font-weight: 600;
    text-align: center;
    text-transform: none;
    word-wrap: break-word;
}