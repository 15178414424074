/*
 *
 *   EGRET - Angular Material Design Admin Template
 *
 *
*/
@font-face {
    font-family: 'MyFont';
    src: url('../font/RobotoCondensed-Regular.ttf');
    font-size: 14px !important;

  }

// // Third pirty style files
// // Sidebar Scroll
@import '~perfect-scrollbar/css/perfect-scrollbar.css';
// // Flag icon
@import '~flag-icon-css/css/flag-icon.min.css';

// //  star-rating styles (Used in Shop app)
// @import '~css-star-rating/dist/css/star-rating.min.css';
// // Data Table
// @import '~@swimlane/ngx-datatable/index.css';
// @import '~@swimlane/ngx-datatable/themes/material.css';
// @import '~@swimlane/ngx-datatable/assets/icons.css';
// // Rich Text Editor
// // Drag and Drop
// // Calendar
@import '~angular-calendar/css/angular-calendar.css';

// // Page loader Topbar
@import '../../vendor/pace/pace-theme-min.css';

// Highlightjs theme
@import '~highlight.js/styles/darcula.css';
@import '~highlight.js/styles/github.css';
@import '~highlight.js/styles/github-gist.css';

// Main Variables File
@import 'scss/variables';
@import 'scss/tableau';
@import 'scss/alert';
@import 'scss/snackbar';
@import 'scss/modal';
@import 'scss/champ';
@import 'scss/matDropdwon';
@import 'scss/busy';



@import 'scss/mixins/mixins';
@import 'scss/colors';
@import 'scss/scrolbar';

@import "scss/animations";
@import "scss/modal-form/modal-form.scss";
@import "scss/_notification.scss";

@import "scss/pagination";
@import "scss/card";
@import "scss/datepiker";

@import 'scss/views/views';


// Open _main.scss and keep files which are required for your current layout.
@import 'scss/matAccordeon';
@import 'scss/main/main';

// Angular material theme files
@import 'scss/themes/init';

@import 'scss/utilities/utilities';
@import 'scss/components/components';
@import 'scss/palette';
@import 'scss/popover';

@import 'scss/page-layouts';

// Open _views.scss and remove unnecessary view related files
// @import 'scss/views/views';

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/dist/css/bootstrap.min.css';

/* Importing Datepicker SCSS file. */
// @import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";
@import "~bootstrap/scss/bootstrap";

/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";

/* Importing Datepicker SCSS file. */
// @import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";

// Modal style
@import 'scss/modal-form/modal-form.scss';
// @import '~ngx-ui-switch/ui-switch.component.scss';



/* Importing Datepicker SCSS file. */
@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";

.egret-navy .mat-drawer-container {
  // width: 80%;
  // background-color: rgb(235, 232, 229) !important;
  // background-color: rgb(167, 137, 137) !important;
  // background-color: rgb(242, 243, 247) !important;
  // background-color: rgb(243, 246, 255) !important;
  background: rgb(239, 241, 253) !important ;

  // margin-top: -50px !important;
  // padding: 0px !important;
  // font-size: 12px !important;
  // font-family: 'Roboto', sans-serif !important;
  // font-family: sans-serif;             
  // font-size: 16px !important;
}
.mat-menu-item {
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  outline: none; 
  border: none;
  -webkit-tap-highlight-color: transparent;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 20px !important;
  height: 38px !important;;
  display: inline !important;
  padding: 0 16px;
  text-align: left;
  /* text-decoration: none; */
  max-width: 100%; 
  position: relative;
}
.mat-menu-content:not(:empty) {
   padding-top: 0px !important; 
   padding-bottom: 0px !important;
}
.mat-menu-panel {
  min-width: 112px;
  max-width: 380px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  max-height: calc(100vh - 48px); 
  border-radius: 4px;
  outline: 0;
  min-height: 0px !important;
}
.modal-width-75 {
  width: 75%;
  max-width: none;
}
.modal-width-70 {
  width: 70%;
  max-width: none;
}

.modal-width-65 {
  width: 65%;
  max-width: none;
} 
.modal-width-60 {
  width: 60%;
  max-width: none;
}

.modal-width-50 {
  width: 50%;
  max-width: none;
}

.modal-width-90 {
  width: 90%;
  max-width: none;
  max-height: 100vh !important;
  min-height: 100vh !important;
}

.modal-width-80 {
  width: 80%;
  max-width: none;
}

.default-busy {
background:#39383850 !important; 
}
.default-busy {
  background: #39383850 !important;
}
ng-busy-default-sign {
  /* display: flex !important;
  align-items: center !important; */
  top: 200px !important; 
}
// .mat-tab-label {
//   height: 48px;
//   padding: 0 24px;
//   cursor: pointer;
//   box-sizing: border-box;
//   opacity: .8;
//   min-width: 160px;
//   text-align: center;
//   font-size: 15px !important;
//   display: inline-flex;
//   justify-content: center;
//   align-items: center;
//   white-space: nowrap;
//   position: relative;
// }
// .mat-tab-label .mat-tab-label-content {
//   display: inline-flex;
//   justify-content: center;
//   align-items: center;
//   white-space: nowrap;
//   background: rgb(239, 241, 253) !important ;
//   padding-top: 3px !important;
//   padding-bottom: 3px !important;
//   padding-left: 10px !important;
//   padding-right: 10px !important;
// }
.mat-drawer-content {
  // overflow: hidden !important;
  overflow-x: hidden !important;
}
.btn-modifier , .btn-supprimer{
  cursor: pointer !important;
}

// .branding {
//   display: flex;
//   align-items: center;
//   position: fixed;
//   padding: 0 16px;
//   width: 17rem !important;
//   height: 156px;
//   line-height: 56px;
//   top: 0;
//   left: 0;
//   z-index: 999;
//   color: #444;
//   background: #ffffff;
//   transition: all 0.3s cubic-bezier(0.35, 0, 0.25, 1);
//   overflow: hidden;
//   padding: 1rem 0;
//   margin: 0 auto 8px;
// }
// .sidebar-panel .sidebar-hold {
//   width: 17rem !important;
// }
// .app-admin-container.sidebar-full:not(.compact-toggle-active) .main-content-wrap {
//   width: calc(100% - 17rem);
// }
// .app-admin-container.sidebar-full:not(.compact-toggle-active) .sidebar-panel {
//   width: 17rem;
//   overflow: hidden;
// }
#search-clear{
  color: #39383850 !important;
  background-color: #39383850 !important;
}