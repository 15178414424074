
@font-face {
    font-family: 'MyFont';
    src: url('../../font/RobotoCondensed-Regular.ttf');
    font-size: 14px !important;

  }
  .mat-expansion-panel-header-title,
.mat-expansion-panel-header-description {
    display: flex;
    flex-grow: 1;
    // margin-right: 25px !important;
    align-items: center;
}

.mat-expansion-panel-header {
    font-size: 15px !important;
    font-weight: 500 !important;
}

.egret-navy .mat-expansion-panel-header-title {
    // color: rgb(0 0 0 / 87%);
}

.mat-expansion-panel-header:not([aria-disabled=true]) {
    cursor: pointer;
    justify-content: center;
    display: flex;
    align-items: center;
    //background-color:#686767 !important;
    // background-color:#006eff !important;
    background-color:#a0a0a0 !important;
     //background-color:#075c99 !important;
    // margin-left: 50px !important;
    height: 55px !important;
}

.mat-accordion .mat-expansion-panel:first-of-type {
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    margin-left: 10px !important;
    margin-bottom: 20px !important;
}

.mat-expansion-panel-body {
    padding: 0 24px 16px;
    background-color: #FFF !important;
}

.mat-expansion-panel-body {
    border: 1px solid rgb(236 238 238);
    padding: 0px;
    padding-top: 12px !important;
    padding-bottom: 0px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;

}

.mat-expanded .mat-expansion-panel-header {
    margin: 0px !important;
}

.mat-expansion-panel-header {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    padding-left: 30px !important;
}

.mat-expansion-panel-header.mat-expanded {
    height: 60px !important;
}

.mat-expansion-panel-header-title,
.mat-expansion-panel-header-description {
    display: flex;
    flex-grow: 1;
    margin-right: 16px;
    align-items: center;
    color: #fff !important;
    font-weight: bold;
    font-size: 15px;

}

.mat-expansion-indicator {
    color: #fff !important;

}

.mat-expansion-indicator::after {
    border-style: solid;
    border-width: 0 2px 2px 0;
    content: "";
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    vertical-align: middle;
    color: #fff !important;

}
.euss{
//   --bs-gutter-x: 0rem  !important;
//   margin-bottom: -28px !important;
}
