


@font-face {
  font-family: 'MyFont';
  src: url('../../../font/RobotoCondensed-Regular.ttf');
  font-size: 14px !important;

}

//Supprimer le box shadow des input
textarea:focus, 
textarea.form-control:focus, 
input.form-control:focus, 
input[type=text]:focus, 
input[type=password]:focus, 
input[type=email]:focus, 
input[type=number]:focus, 
[type=text].form-control:focus, 
[type=password].form-control:focus, 
[type=email].form-control:focus, 
[type=tel].form-control:focus, 
[contenteditable].form-control:focus,
select:focus,
select.form-select:focus {
  box-shadow: none;
}




h4 {
  font-weight: bold;
  font-size: 1.2em;
}
h5 {
  padding-top: 10px;
  padding-left: 15px;
  color: #5e5e5e;
  text-shadow: #3c3d3f .5px 1px 1px!important;
  font-size: 17px!important;
  font-weight: bold !important;

}

th {
  font-weight: 200;
  min-width: 50px;
}

button,
th {
  color: #ffffff;
}
.card {
  background-color: #ffffff;
  border-radius: 5px;
  border-style: none;
  margin-left: 30px;
  margin-right: 30px;
  // box-shadow: 0.1px 0.1px 0.1px #3c3c3c;

  // button {
  //   color: #ffffff;
  //   background-color: #222a45;
  //   &:active{
  //     color: #222a45 !important;
  //   background-color: #ffffff !important;
  //   border: 1px solid #222a45 !important;
  //   }
  // }

  // button:hover {
  //   color: #222a45;
  //   background-color: #ffffff;
  //   border: 1px solid #222a45;
  // }

  .card-header {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 20px;
  }

  .card-body,
  tbody {
    .input-group, .form-control:focus {
      border-color: transparent;
      box-shadow: none;
    }
    .btn-modifier {
      color: #6fdb44;
    }
    .btn-supprimer {
      color: #e74141;
    }
  }
}
.table-head {
  background-color: #222a45; 
}


.modal-body {
  fieldset,
  legend {
    all: revert;
  }
  fieldset {
    
      border: 2px solid #eceeee !important;
      border-radius: 5px;
      padding: 8px;
  }
  
  legend {
    font-size: 14px;
    color: #fb5a5a;
    font-weight: bold;
  }
  .row {
    margin-bottom: 5px;
    .form-group {
      margin: 3px 0px;

      .table-head {
        background-color: #222a45;
      }
    }
  }
  .btn-modifier {
    color: green;
  }
  .btn-supprimer {
    color: red;
  }
}


.btn-credit{
  font-size: 12px !important;
  background-color: #582900 !important;
  border: 1px solid #582900 !important;
  color: #fff !important;
  &:hover{
    font-size: 12px !important;
  background-color: #fff !important;
  border: 1px solid #582900 !important;
  color: #582900 !important;
  }
}

.btn-print {
  font-size: 12px !important;
  background-color: #ff729a !important;
  border: 1px solid #ff729a !important;
  color: #fff !important;
}
.btn-print:hover {
  font-size: 12px !important;
  background-color: #fff !important;
  border: 1px solid #ff729a !important;
  color: #ff729a !important;
}

.btn-valider{
  font-size: 12px !important;
  background-color: green !important;
  border: 1px solid green !important;
  color: #fff;
}
.btn-valider:hover{
  font-size: 12px !important;
  background-color: #fff !important;
  border: 1px solid green !important;
  color: green !important;
}
.btn-apercu{
  background-color: white;
  border: 1px solid #222a45;
  color: #222a45 !important;
  font-size: 12px !important;
}
.btn-apercu:hover {
  background-color: #222a45 !important;
  color: #ffffff !important;
  font-size: 12px !important;

}
.pasD{
  width: 100%;
  background-color: rgba(255, 0, 0, 0.858);
  color: #fff;
  padding: 7px;
  border-radius: 7px;
}
.dispo{
  width: 100%;
  background-color: rgba(6, 94, 12, 0.842);
  color: #fff;
  padding: 7px;
  border-radius: 7px;
}

//
//change ng select height
.ng-select .ng-select-container {
  min-height: 20px;
}

.ng-select.ng-select-single .ng-select-container {
  height: 20px;
}
.btn-primary{
  background-color: #222a45 !important;
  color: #ffffff !important;
  font-size: 12px !important;
  border: 1px solid #222a45 !important;

  &:hover{
    background-color: #ffffff !important;
    border: 1px solid #222a45 !important;
    color: #222a45 !important;
    font-size: 12px !important;
  }
}

.btn-save , .custom_btn_primary{
  background-color: #222a45 !important;
  color: #ffffff !important;
  font-size: 12px !important;
  // box-shadow: inset 0 0 0 #222a45, 0 5px 0 0 #222a45, 0 10px 5px #999999 !important;
  &:hover{
    background-color: #ffffff !important;
    border: 1px solid #222a45 !important;
    color: #222a45 !important;
    font-size: 12px !important;
  }
  &:active{
    // box-shadow: inset 0 0 0 #222a45, 0 2px 0 0 #222a45, 0 5px 3px #999999 !important;

  }

}
.btn-cancel{
  background-color: #777 !important;
  color: white !important;
  font-size: 12px !important;
  border: 1px solid #777 !important;
  // box-shadow: inset 0 0 0 #777, 0 5px 0 0 #777, 0 10px 5px #999999 !important;


  &:hover{
      background-color: white !important;
      color: #777 !important;
      font-size: 12px !important;
      border: 1px solid #777 !important;
  }
  &:active{
    // box-shadow: inset 0 0 0 #777, 0 2px 0 0 #777, 0 5px 3px #999999 !important;

  }
}

