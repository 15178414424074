.snackbar-success {
  background-color: rgba(24, 206, 15, 0.8);
  color: #ffffff;
  z-index: 1000;
  width: 100px;
  position: absolute;
  top: 1%;
  right: 1%;
  font-weight: 500;
  transition: 0.8s;
}

.snackbar-info {
  background-color: rgba(44, 168, 255, 0.8);
  color: #ffffff;
  z-index: 1000;
  width: 100px;
  position: absolute;
  top: 1%;
  right: 1%;
  font-weight: 500;
  transition: 0.8s;

}

.snackbar-warning {
  background-color: rgba(255, 230, 0, 0.8);
  color: #ffffff;
  z-index: 1000;
  width: 100px;
  position: absolute;
  top: 1%;
  right: 1%;
  font-weight: 500;
  transition: 0.8s;
}

.snackbar-danger {
  font-size: 10px;
  background-color: rgba(255, 0, 0, 0.8);
  color: #ffffff;
  z-index: 9000;
  // width: 50px !important;
  position: absolute;
  top: 1%;
  right: 1%;
  font-weight: 500;
  transition: 0.8s;
  height: 30px;


}