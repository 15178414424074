.seciton-left {
  color: white;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  min-width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat !important;
  background: url("../../../../images/illustrations/signin.jpg");
  background-size: 110% 95%;
  background-position: center center;
  padding: 30px 15px !important;
 

  .section-left-content {
    max-width: 380px;
  }
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    background: rgba(135, 188, 234, 0.114);
    z-index: -5;
  }
}

.form-holder {
  padding: 16px;
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  min-width: 50%;

  .full-width{
    margin-bottom: 10px;
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
}

.signup-form {
  padding: 20px 20px 70px 20px;
}
.niveau2{
  background: url("../../../../images/illustrations/signin.jpg");
  width: 100%;
    height: 100vh;
    background-size: 70% 100%;
  background-position: center center;
  background-repeat: no-repeat;
  padding: 0 !important;
  
}
.fade-in-image {
  animation: fadeIn 3s;
  -webkit-animation: fadeIn 3s;
  -moz-animation: fadeIn 3s;
  -o-animation: fadeIn 3s;
  -ms-animation: fadeIn 3s;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@-moz-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@-webkit-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@-o-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@-ms-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
