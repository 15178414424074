// .app-admin-container .rightside-content-hold {
//     display: flex;
//     flex-direction: column;
//     flex-grow: 1;
//     padding: 0.333333rem;
//     overflow-x: hidden !important;
//     overflow-y: auto;
//     position: relative;
//     min-height: none !important;

// }

::-webkit-scrollbar-track {
    border-radius: 100vw;
    margin-block: .5em;
}

::-webkit-scrollbar-thumb {
    background: rgb(184, 183, 183);
    height: 120px;
    width: 122px;
    border-radius: 100vw;

}

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: rgb(162, 159, 159);
    border-radius: 100vw;

}