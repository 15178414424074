.body{
    padding: 0px !important;
    border: 0px !important;
    border: none !important;
    background-color: transparent !important;

    
    
  }
  .popover-header{
    background-color: #4997f7 !important;
    color: #fff !important;
    border-radius: 5px 5px 0px 0px !important;
    font-size: 20px !important;
    border: none !important;
    
  }
  .popover-header {
    padding: 20px !important;
  
    &:empty {
      display: none;
    }
  }
  .popover{
      padding: 0 !important;
      margin: 0 !important;
      border: none !important;
      box-shadow: 0.1px 0.1px 0.1px #000000 !important;
    }
    
  .popover .popover-arrow::before, .popover .popover-arrow::after{
    left: 20px !important;
    border-radius: 5px !important;
    color: #4997f7 !important;
  }
  .popover-body{
      border: none !important;
      border-radius: 0px 0px 5px 5px !important;
      background:#f7f6f3 !important;
    //   background:#fdfaf5 !important;
    // background: rgb(237, 237, 241) !important ;

      color: #000 !important;

  }
  .customClass{
    // background:#f7f6f3 !important;
    // transition: 3s !important;
    box-shadow: 0.1px 0.1px 0.1px #000000 !important;
}

    
element.style {
    display: block;
    will-change: transform;
    top: 0px;
    left: 0px;
    // transform: translate3d(9px, 48px, 100px) !important;

}