
@font-face {
    font-family: 'MyFont';
    src: url('../../font/RobotoCondensed-Regular.ttf');
    font-size: 14px !important;

  }
  .page-item.active .page-link {
    background-color: #33406c !important;
    // border-color: #d41500;
    border-radius: 50%;
    box-shadow: none;
    padding: 0px 12px;
    min-width: 30px;
    line-height: 30px;
    color: #ffffff !important;
    text-transform: uppercase;
    border: none !important;

}


// // .page-item.active .page-link {
// //     z-index: 3;
// //     color: #fff;
// //     background-color: #0d6efd;
// //     border-color: #0d6efd;
// // }

// .page-item:first-child .page-link {
//     background-color: #00bcd4;
//     border-color: #00bcd4;
//     border-radius: 50%;
//     margin: 5px;
//     box-shadow: 0 4px 5px 0 #d4d8da,
//         0 1px 10px 0 #d4d8da,
//         0 2px 4px -1px #d4d8da;
//     padding: 0px 12px;
//     min-width: 30px;
//     line-height: 30px;
//     color: #ffffff;
//     text-transform: uppercase;
// }

// .pagination-sm {
//     --bs-pagination-padding-x: 0.5rem;
//     --bs-pagination-padding-y: 0.25rem;
//     --bs-pagination-font-size: 0.875rem;
//     --bs-pagination-border-radius: 0.25rem;
// }

// .pagination-sm li {
//     display: list-item;
//     text-align: -webkit-match-parent;
//     background-color: #00bcd4;
// }

button,
input,
select,
a {
    outline: none !important;
    font-size: 14px !important;
    text-decoration: none;
    box-shadow: none !important;
}

.paginator-per-page {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 20px;
    color: #555 !important;
    border: none !important;

}

// tfoot {
//     border: none !important;

//     td {
//         border-bottom: 0px !important;

//         .span_total_entree {
//             color: #0665aa;
//             font-weight: bold;
//         }
²
//         .pagination-part {
//             display: flex;
//             align-items: center;
//         }
//     }
// }
.page-link {
    position: relative;
    display: block;
    padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
    font-size: var(--bs-pagination-font-size);
    color: #444 !important;
    text-decoration: none;
    background-color: var(--bs-pagination-bg);
    border: none !important;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    border: none !important;

    &:hover {
        color: #444 !important;
        background-color: #fff !important
    }
}

.page-item:not(:first-child) .page-link {
    margin-left: 2px !important;
}
.page-link {
    position: relative;
    display: block;
    padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
    font-size: var(--bs-pagination-font-size);
    color: var(--bs-pagination-color);
    text-decoration: none;
    color: #444 !important;
    border: none !important;
    background-color: #fff !important;
    font-size: 12px !important ;
    background-color: var(--bs-pagination-bg);
    border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    &:hover{

    }
}