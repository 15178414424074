

@font-face {
    font-family: 'MyFont';
    src: url('../../font/RobotoCondensed-Regular.ttf');
    font-size: 14px !important;

  }
  .theme-dark-blue {
    background-color: #fff !important;
}
.theme-dark-blue .bs-datepicker-head {
}

.bs-datepicker-head {
    min-width: 270px !important;
    height: 50px !important;
    padding: 10px !important;
    border-radius: 3px 3px 0 0;
    text-align: justify !important;
}

.bs-datepicker-body table th {
    font-size: 10px !important;
    font-weight: 400 !important;
    text-align: center !important;
    background-color: #fff !important;

    thead {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }

}

::ng-deep .ng-star-inserted {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.bs-datepicker-body table th {
    font-size: 10px !important;
    font-weight: 400 !important;
    text-align: center !important;
    background-color: rgb(255 255 255) !important;
}

.bs-datepicker-body table td tr {
    color: rgb(84 112 139);
    text-align: center;
    position: relative;
    padding: 0px !important;
}
.bs-datepicker-container {
    padding: 10px !important;
        box-shadow: 2px 1px 3px 5px rgba(175, 172, 172, 0.1);
}
.bs-datepicker-body {
    padding: 10px;
    border-radius: 0 0 3px 3px;
    min-height: 232px;
    min-width: 278px;
     border: none !important
}