*,
*::before,
*::after {
    box-sizing: border-box;
}

.modal-footer-custom {
    border-top: 0px solid #eceeee !important;
    padding: 0px;
}

tfoot {
    border: none !important;

    td {
        border-bottom: 0px !important;

        .span_total_entree {
            color: #33406c;
            font-weight: bold;
        }

        .pagination-part {
            display: flex;
            align-items: center;
        }
    }
}

.table-head {
    th {
        a {
            cursor: pointer;
        }
    }
}

table {
    .checkbox {
        [type="checkbox"]+label {
            margin: 0;
            height: 20px;
            padding-left: 20px;
            vertical-align: middle;
        }
    }
}

.isReject {
    td {
        color: rgb(215, 111, 111) !important;
    }
}

.custom-td-action {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.custom-width-td-action {
    width: 130px !important;
}

.table-head {
    th {
        // background-color: #d2e1f2 !important;
        background-color: #222a45 !important;
        color: #fff !important;
        height: 10px !important;
    }
}

table thead tr th {
    padding: auto !important;
    // background: rgb(239, 241, 253) !important ;

    background-color: #f3f3f3 !important;
}

.table-head th {
    // background-color: rgb(113 113 113) !important;
    color: rgb(217 216 216) !important;
    height: 20px !important;
    vertical-align: middle !important;
    text-shadow: rgb(217 216 216) .5px 1px 1px !important;
    font-weight: 700 !important;
    font-size: 14px !important;

}

tfoot td .span_total_entree {
    color: #33406c !important;
    // font-weight: bold !important;
}


.table>:not(caption)>*>* {
    padding: .9rem 0.6rem !important;
    background-color: var(--bs-table-bg);
    border: none;
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
    vertical-align: middle !important;
}

// .table thead tr th {
//     // padding: 0px 10px !important;
//     vertical-align: middle !important;
//     height: 50px !important;
//     color: rgb(102 102 102);
//     border: none;

// }

th {
    // vertical-align: middle !important;
}

.fort {
    margin-bottom: -20px !important;
}

.bordure {
    border-top-right-radius: 0.2rem;
    border-top-left-radius: 0.2rem;
}

.custom-autre-tableau {
    cursor: pointer;
    position: absolute;
    margin-top: -52px;
    // margin-left: 1px;
    width: 100%;
}

.custom-autre-head {
    border-top-right-radius: 500px !important;
    border-top-left-radius: 5px !important;
}

.custuom-image-photo {
    width: 150px;
    height: 70px;
    background-size: cover;
}

#smull .custom-td-action {
    .custom-btn-delete {
        .material-icons {

            font-size: 10px !important;
        }
    }
}
    
tfoot {
    tr {

        td {

            .txt-align-center {
                color: #212529 !important;
                font-size: 12px !important;
                text-shadow: #212529 .5px .5px .5px;
                // background-color: #717171;
                // width: 100%;
            }
        }
    }
}
.txt-align-center {
    color: #212529 !important;
    font-size: 12px !important;
    text-shadow: #212529 .5px .5px .5px;
    // background-color: #717171;
    // width: 100%;
}

.custom-font {
    color: #61666b !important;
    font-size: 13px !important;
    text-shadow: #a0a6ac .5px .5px .5px;
}

.table tbody tr td,
.table tbody tr th {
    padding: 10px; 
    border-top: 0px solid #eee;
    border-bottom: 0px solid #eee;
    vertical-align: middle;
}

tr>td {
    font-size: 12px !important;
    padding: 5px;
}
.animate__animated.animate__bounce {
    --animate-duration: 1s;
  }
  
  /* This changes all the animations globally */
  :root {
    --animate-duration: 500ms;
    --animate-delay: 1s;
  }
  .table-striped>tbody>tr:nth-of-type(odd)>* {
    background: rgb(239, 241, 253) !important ;
    // background-color: #f3f3f3 !important;

        color: none !important;
}
 .isInactif{
    background-color: #f4f4f0 !important;
 }

 .table-bordered thead tr th {
    background-color: #717171 !important;
    padding: 5px !important;
    border: 1px solid #eee !important;
    height: 0px !important;
    border-bottom: 2px solid #fff !important; 
    thead tr th{
        border-top: none !important; 

    }   
}

.table-borderede thead tr th {
    background-color: #0d60fd !important;
    padding: 5px !important;
    border: 1px solid #eee !important;
    height: 0px !important;
    border-bottom: 2px solid #fff !important; 
    thead tr th{
        border-top: none !important; 

    } 
}
.table-borderless thead tr th{
    // padding:  10px !important;
}

.table-bordered tbody tr td, .table tbody tr th {
    padding: 7px !important;
    border-top: 1px solid #eee !important;
    border-bottom: 1px solid #eee !important;
    vertical-align: middle !important;
    text-shadow: rgb(217 216 216) .5px 1px 1px !important;
    font-weight: 700 !important;
}
tfoot{
    tr{
        .txt-align-center{

            border-top: 1px solid #eee !important;
            border-bottom: 1px solid #eee !important;
            vertical-align: middle !important;
            background: rgb(237, 237, 241) !important ;
            // width: 120%;
            margin-left: -10px !important;
            margin-right: -10px !important;
            height: 75px !important; 
            margin-top: -12px !important;
            &:hover{
                background: rgb(239, 241, 253) !important ;
        
            }
            td{
                border-top: 1px solid #eee !important;
                border-bottom: 1px solid #eee !important;
            }
        }
        
    
    }
}

td {
    img{
        transition: ease-in-out .5s ;
        
       &:hover{
            scale: 1.3;
        }
    }
}

tfoot{
    tr{
        td{
            .pagination-part{
                height: 50px !important;
                margin-top: -2px !important;
                margin-left: -10px !important;
                margin-right: -10px !important;
                padding: 5px !important;
                .pagination-sm {
                    display: flex !important;
                    align-items: center !important;
                    margin-bottom: 0 !important;
                    // background-color: rgb(215, 226, 235) !important;
                }
                ul{
                    display: flex !important;
                    align-items: center !important;
            }
    }}}}
        .txt-align-center{

            text-align: center !important;
            // display: flex;
            // justify-content: center;
            // align-items: center;
            color: #212529 !important;
        }
        .cle{
            color: #4c4c50 !important;
            &:hover{
                color: #212529 !important;
                text-decoration: underline;
                cursor: pointer;
            }
        }
