@font-face {
    font-family: 'MyFont';
    src: url('../../font/RobotoCondensed-Regular.ttf');
    font-size: 14px !important;

  }
  .snackbar-success {
    background-color: rgba(24, 206, 15, 0.8) !important;
    z-index: 999999999999 !important;
    color: #ffffff;
}

.snackbar-info {
    background-color: rgba(44, 168, 255, 0.8) !important;
    z-index: 999999999999 !important;
    color: #ffffff;
}

.snackbar-warning {
    background-color: rgba(255, 230, 0, 0.8) !important;
    z-index: 999999999999 !important;
    color: #ffffff;
}

.snackbar-danger {
    background-color: rgba(255, 0, 0, 0.8) !important;
    z-index: 999999999999;
    color: #ffffff;
}