

.cdk-overlay-connected-position-bounding-box {
    z-index: 1 !important;
}

.mat-dialog-content {
    margin: 0px !important;
    padding: 0px !important;
    border-radius: 10px !important;

}

.modal-width-75 {
    width: 80%;
    max-width: none;
}

.modal-width-65 {
    width: 70%;
    max-width: none;
}

.modal-width-50 {
    width: 60%;
    max-width: none;
}

.modal-width-75 {
    width: 75%;
    max-width: none;
  }
  .modal-width-70 {
    width: 70%;
    max-width: none;
  }
  
  .modal-width-65 {
    width: 65%;
    max-width: none;
  } 
  .modal-width-60 {
    width: 60%;
    max-width: none;
  }
  
  .modal-width-50 {
    width: 50%;
    max-width: none;
  }
  
  .modal-width-90 {
    width: 90%;
    max-width: none;
    max-height: 100vh !important;
    min-height: 100vh !important;
  }
  
  .modal-width-80 {
    width: 80%;
    max-width: none;
  }
ng-busy-default-sign {
    /* display: flex !important;
    align-items: center !important; */
    top: 200px !important; 
  }


default-busy {
    background: #39383850 !important;
}
.modal-body{
    padding: 15px 25px !important;
}





.modal .modal-header {
    // #0665aa !important; 
    padding: 10px !important; 
    color: #fff;
}

.modal-title {
    color: #fff;
    font-weight: 700;
    text-shadow: #fff 1px 0 1px;

}


.modal-content {
    padding: 0px !important;
    border-radius: 15px !important;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.3) !important;
    border: none !important;

}

.modal-backdrop {
    z-index: 99 !important;
}

.modal {
    background-color: #ffffff50!important;
    max-height: 100%;
    width: 100%;
    z-index: 999 !important;

    .modal-header {
        border: none;
        border-top-left-radius: 5px !important;
        border-top-right-radius: 5px !important;

        padding: 0px;
        background-color: #222a45; 
        border-bottom: 1px solid #d6cece !important ;;
        .modal-title {
            font-weight: bold;
            font-size: 16px;
        }
    }

    .modal-content {
        border-top-left-radius: 100px !important;
        padding: 10px;

        .modal-body {
            padding: 15px 25px;
            background-color: #fff !important;
        }
    }

    .modal-footer {
        border-top: 1px solid #eceeee!important;
        border-bottom-left-radius: 15px !important;
        border-bottom-right-radius: 15px !important;
        background-color: #fff !important;
        padding: 5!important;
            }
}

.editRowModal {
    .modal-header {
        padding: 5px;
        border-radius: 0 0.55rem 0 0;

        img {
            float: left;
            width: 45px;
        }

        .modal-about {
            float: left;
            padding-left: 10px;
        }

        .modal-with {
            font-weight: bold;
            font-size: 16px;
        }

      
    }
}

.addContainer {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .form {
        display: flex;
        padding-top: 6px;
    }

    .mat-form-field {
        flex-grow: 1;
    }

    .modalHeader img {
        border-radius: 50%;
    }
}

.modalHeader {
    display: flex;

    align-items: flex-start;

    justify-content: space-between;
    padding: 0px 10px 0px 10px;
}

.modal-close-button {
    background-color: transparent !important;
    box-shadow: none !important;
    color: #fff !important;
}
.cdk-overlay-connected-position-bounding-box {
    z-index: 1052 !important;
}

.mat-dialog-content {
    margin: 0px !important;
    padding: 0px !important;
}

.modal-width-75 {
    width: 80%;
    max-width: none;
}

.modal-width-65 {
    width: 70%;
    max-width: none;
}

.modal-width-50 {
    width: 60%;
    max-width: none;
}

.modal-width-90 {
    width: 95%;

    max-width: none;
}

.modal-width-80 {
    width: 90%;
    max-width: none;
}

.modal-width-30 {
    width: 40%;
    max-width: none;
}

.modal-width-40 {
    width: 50%;
    max-width: none;
}
.modal-width-35 {
    width: 45%;
    max-width: none;
}
.modal-width-25 {
    width: 35%;
    max-width: none;
}


default-busy {
    background: #08080850 !important;
}

.modal-header{
    mat-icon{
        font-size: 15px !important;
        text-align: center !important;
        transition: all ease-in-out .5s !important;
        scale: 1.1;
        &:active{
            background-color: inherit !important;
        }
        &:hover{
            transition: all ease-in-out .5s !important;

            scale: 1.8;
        }
    }
}